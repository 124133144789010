import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import Header from "components/headers/light.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
import styled from "styled-components";

import PrettierClientSearchDropdown from "../release-notes/release-20210331/prettier-client-search-dropdown.gif"

import ResendInvitePortal from "../release-notes/release-20210410/resend-invite-portal.png";
import ResendInviteShopify from "../release-notes/release-20210410/resend-invite-shopify.png";
import MobileView20210501 from "../release-notes/release-20210501/mobile-ui-new.gif";
import InAppNavigation20210518 from "../release-notes/release-20210518/in-app-navigation.png";
import ProofOfDelivery20210526 from "../release-notes/release-20210526/proof-of-delivery.gif";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-gray-800`}
  p {
    ${tw`mt-2`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  pre { 
    ${tw`bg-gray-100 text-black p-1`}
  }
  span.tag {
    ${tw`text-orange-500 border border-orange-500 rounded font-bold text-xs mr-2 p-1 inline-block uppercase`}
  }
  hr {
    ${tw`my-4`}
  }

  img.mobile {
    ${tw`sm:w-2/4 md:w-1/4 w-full`}
  }
`;

export default ({ headingText = "What's New" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <Release20211227 />
            <Release20211221 />
            <Release20211219 />
            <Release20211123 />
            <Release20211103 />
            <Release20211012 />
            <Release20210811 />
            <Release20210526 />
            <Release20210518 />
            <Release20210507 />
            <Release20210501 />
            <Release20210417 />
            <Release20210413 />
            <Release20210410 />
            <Release20210405 />
            <Release20210402 />
            <Release20210331 />
            <Release20210315 />
            <Release20210219 />
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  )
}


function Release20211227() {
  return (
    <>
      <div>
        <h3>Software Attribution</h3>
        <small>Dec, 27st, 2021</small> {tags(["portal", "api", "mobile"])}
      </div>
      <div>
        <p>
          For API Users: List of open source software licenses can be found <a href="https://api.fleetonroute.com/public/oss-licenses.txt">{pre("here")}</a>
        </p>
        <p>
          For Portal Users: List of open source software licenses can be found <a href="https://portal.fleetonroute.com/oss-licenses.txt">{pre("here")}</a>
        </p>
      </div>
      <hr />
    </>
  )
}

function Release20211221() {
  return (
    <>
      <div>
        <h3>Portal Update - scheduledAt</h3>
        <small>Dec, 21st, 2021</small> {tags(["portal"])}
      </div>
      <div>
        <p>
          For Portal Users: {pre("scheduledAt")} will be updated with the current date if it is null when the {pre("TripStatus")} is to {pre("SCHEDULED")}.
        </p>
      </div>
      <hr />
    </>
  )
}


function Release20211219() {
  return <>
    <div>
      <h3>Bugfix - Proof of delivery</h3>
      <small>Dec 19th, 2021</small> {tags(["android"])}
    </div>
    <div>
      <p>
        For Mobile Users: We fixed a bug preventing photo proof of deliveries from being captured.
      </p>
    </div>
    <hr />
  </>
}

function Release20211123() {
  return <>
    <div>
      <h3>Portal Updates - Dark Mode</h3>
      <small>Nov, 23rd, 2021</small> {tags(["portal"])}
    </div>
    <div>
      <p>
        For Portal Users: We've added a dark mode theme to suit your preference.
      </p>
    </div>
    <hr />
  </>
}

function Release20211103() {
  return <>
    <div>
      <h3>Route Optimization!</h3>
      <small>Nov, 03rd, 2021</small> {tags(["api", "android", "ios"])}
    </div>
    <div>
      <p>
        For API Users: Optimized route can be can be calculated using <a href="https://api.fleetonroute.com/api/documentation/#/navigation/optimizeRoute">{pre("Navigation.optimizeRoute")}</a>
      </p>
      <p>
        For Mobile Users: You can take advantage of route optimization to plan your trips. You can select the optimal route from the Trips page dropdown menu.
      </p>
    </div>
    <hr />
  </>
}

function Release20211012() {
  return <>
    <div>
      <h3>iOS App released to the App Store!</h3>
      <small>Oct, 12th, 2021</small> {tags(["ios"])}
    </div>
    <div>
      <p>
        For Mobile Users: We've released our iOS app. You can download it on the <a href="https://apps.apple.com/us/app/fleetonroute/id1585593801">Apple App Store</a>
      </p>
    </div>
    <hr />
  </>
}

function Release20210811() {
  return <>
    <div>
      <h3>API Improvements</h3>
      <small>Aug, 11th, 2021</small> {tags(["api"])}
    </div>
    <div>
      <p>
        For API Users: We've added an several backwards compatible updates to our API. There are no breaking changes. The latest version of the API is {pre("1.19.11")}
      </p>
    </div>
    <hr />
  </>
}

export function Release20210526() {
  return <>
    <div>
      <h3>Proof of Delivery</h3>
      <small>May, 26th, 2021</small> {tags(["api", "shopify", "android"])}
    </div>
    <div>
      <p>
        For API Users: We've added an endpoint to upload the proof of delivery: <a href="https://api.fleetonroute.com/api/documentation/#/trips/uploadProofOfDelivery">{pre("Trips.uploadProofOfDelivery")}</a>. Either the proof of delivery image or the package recipient name can be submitted with the payload.
      </p>
      <p>
        For Mobile Users: The proof of delivery screen automatically appears when you are within 25 meters of the delivery address.
      </p>
      <p>
        For Shopify Users: You can review the proof of delivery by clicking the {pre("Note")} icon on the deliveries page.
        <img src={ProofOfDelivery20210526} />
      </p>
    </div>
    <hr />
  </>
}

export function Release20210518() {
  return (
    <>
      <div>
        <h3>In-App Navigation</h3>
        <small>May, 18th, 2021</small> {tags(["android"])}
      </div>
      <div>
        <p>
          For mobile users: We've added navigation built in to the app. It reduces driver distraction and allows easy access to all the necessary information. The built in navigation also offers voice directions so you do not have to read the screen for directions.
        </p>
        <p>
          For API users - We have added a text-to-speech endpoint to generate audio for navigation steps <a href="https://api.fleetonroute.com/api/documentation/#/navigation/textToSpeech">{pre("Navigation.textToSpeech")}</a>.
        </p>
        <img className="mobile" src={InAppNavigation20210518} />
      </div>
      <hr />
    </>
  )
}

export function Release20210507() {
  return (
    <>
      <div>
        <h3>TripsApi.retrieveTripStatus endpoint added</h3>
        <small>May, 7th, 2021</small> {tags(["api"])}
      </div>
      <div>
        <p>
          For API Users:
          We've added a new endpoint to <a href="https://api.fleetonroute.com/api/documentation/#/trips/retrieveTripStatus">{pre("Trips.retrieveTripStatus")}</a> without authentication. Your customer privacy is important to us, so this unauthenticated endpoint does not return any personal identifiable information like name or phone numbers.
          In order to retrieve trip details with customer information, you can make authenticated requests to <a href="https://api.fleetonroute.com/api/documentation/#/trips/retrieveTrip">{pre("Trips.retrieveTrip")}</a>
        </p>
      </div>
      <hr />
    </>
  )
}



export function Release20210501() {
  return (
    <>
      <div>
        <h3>Revamped Mobile Interface</h3>
        <small>May, 1st, 2021</small> {tags(["android"])}
      </div>
      <div>
        <p>
          For Android Users: We've revamped the user interface and made several interactions simper.
          <img className="mobile" src={MobileView20210501} />
        </p>
      </div>
      <hr />
    </>
  )
}

export function Release20210417() {
  return (
    <>
      <div>
        <h3>Reschedule or reassign deliveries in Shopify</h3>
        <small>April, 17th, 2021</small> {tags(["shopify"])}
      </div>
      <div>
        <p>
          For Shopify Users: Deliveries can be reassigned and rescheduled by using the Edit button on the Deliveries page.
          {/* <h1><i>// TODO: gif showing edit button and modal popup</i></h1> */}
        </p>
      </div>
      <hr />
    </>
  );
}

export function Release20210413() {
  return (
    <>
      <div>
        <h3>Trip ScheduledAt supports multiple formats</h3>
        <small>April, 13th, 2021</small> {tags(["api"])}
      </div>
      <div>
        <p>
          For API Users: As of v0.10.0 <a href="https://api.fleetonroute.com/api/documentation/#/trips/createTrip">{pre("Trips.createClient")}</a> call accepts the scheduledAt property in multiple formats. This is not a breaking change but it is recommended that you upgrade to this version soon.
          <pre>
            # Date as a string - YYYY-MM-DD<br />
            2021-04-13<br /><br />
            # DateTime as a javascript timestamp<br />
            1618314880258 <br /><br />
            # ScheduleDate object <br />
            {
              JSON.stringify({
                year: 2021, month: 3, date: 13, hour: 9, minute: 30, second: 30
              }, null, 2)}</pre>
        </p>
      </div>
      <hr />
    </>
  );
}


export function Release20210331() {
  return (
    <>
      <div>
        <h3>Client search made easier</h3>
        <small>March, 31st, 2021</small> {tags(["portal"])}
      </div>
      <div>
        <p>
          For Portal Users: searching for clients while creating a trips just got easier. You can now search by their name, phone number, or address.
        </p>
        <img src={PrettierClientSearchDropdown} />
      </div>
      <hr />
    </>
  );
}

export function Release20210402() {
  return (
    <>
      <div>
        <h3>Creating a client requires less information</h3>
        <small>April, 2nd, 2021</small> {tags(["api", "portal"])}
        <p>When creating a client, {pre("address")} is optional. You'll still be required to provide an {pre("address")} while setting up a trip.</p>
      </div>
      <div>
        <p>
          <i>For API Users</i>: <a href="https://api.fleetonroute.com/api/documentation/#/organizations/createClient">{pre("Organizations.createClient")}</a> call no longer requires the client's address.
        </p>
        <p>
          <i>For Portal Users</i>: The {pre("address")} field is optional while creating a client.
        </p>
      </div>
      <hr />
    </>
  );
}

export function Release20210405() {
  return (
    <>
      <div>
        <h3>Improved support for phone number formats</h3>
        <small>April, 5th, 2021</small> {tags(["api", "portal"])}
      </div>
      <div>
        <p>We've improved how we handle phone numbers. Several common formats are going to be supported. eg. {pre("(###)-###-####")} , {pre("### ### ####")} or {pre("##########")}.</p>
      </div>
      <hr />
    </>
  )
}


export function Release20210410() {
  return (
    <>
      <div>
        <h3>Resend Driver Invitations</h3>
        <small>April, 10th, 2021</small> {tags(["api", "shopify", "portal"])}
      </div>
      <div>
        <p>Emails sometimes get lost, we get it. You can now resend invitations to your drivers.</p>
        <p><i>For API users</i>: call the <a href="https://api.fleetonroute.com/api/documentation/#/invitations/createInvitation">{pre("Invitations.createInvitation")}</a> method in order to resend the invitation.</p>
        <p><i>For Shopify users</i>: There is a {pre("Resend")} button in the Drivers page.</p>
        <img src={ResendInviteShopify} />
        <p><i>For FleetOnRoute Portal users</i>: to resend the invitation, create the invitation again.</p>
        <img src={ResendInvitePortal} />
      </div>
      <hr />
    </>
  )
}


export function Release20210219() {
  return (
    <>
      <div>
        <h3>Append custom properties to the driver invitation</h3>
        <small>February, 19th, 2021</small> {tags(["api"])}
      </div>
      <div>
        <p><i>For API Users</i>:<a href="https://api.fleetonroute.com/api/documentation/#/invitations/createInvitation">{pre("Invitations.createInvitation")}</a> now supports {pre("extra")} properties. Any data passed in the {pre("extra")} properties will be saved to the {pre("Invitation")}.</p>
      </div>
      <hr />
    </>
  )
}

export function Release20210315() {
  return (
    <>
      <div>
        <h3>Activate and deactivate drivers in Shopify</h3>
        <small>March, 15th, 2021</small> {tags(["shopify"])}
      </div>
      <div>
        <p><i>For Shopify Users</i>: You can now have more than one driver for your shop. Just activate and deactivate drivers as needed.</p>
      </div>
      <hr />
    </>
  )
}

function pre(text) {
  return <pre style={{ display: "inline" }}>{text}</pre>
}

function tags(items) {
  return (
    <>
      {items.map(item => <span className="tag">{item}</span>)}
    </>
  )
}