import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Pricing from "components/pricing/ThreePlans.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Banner from "./HomePage/Banner";
import ForBusinesses from "./HomePage/ForBusinesses";
import MainFooter from "fleetonroute/components/footers/MainFooter";
import ForDrivers from "./HomePage/ForDrivers";
import ForClients from "./HomePage/ForClients";
import KnowYourDelivery from "./HomePage/KnowYourDelivery";
import DownloadApp from "components/cta/DownloadApp";
import SingleCol from "components/faqs/SingleCol.js";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const apiUrl = process.env.GATSBY_API_DOCUMENTATION_URL;
  return (
    <AnimationRevealPage>
      <Banner roundedHeaderButton={true} />
      <KnowYourDelivery
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Know where your deliveries are and <HighlightedText>share it with your customers too!</HighlightedText>
          </>
        }
        description=""
        showDecoratorBlob={false}
      />
      <ForClients
        subheading={<Subheading></Subheading>}
        heading={
          <>
            FleetOnRoute for <HighlightedText>your customers.</HighlightedText>
          </>
        }
        textOnLeft={false}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <ThreePlansWithHalfPrimaryBackground />
      <SingleCol />
      {/* <ForBusinesses
        subheading={<Subheading></Subheading>}
        heading={
          <>
            FleetOnRoute for <HighlightedText>businesses.</HighlightedText>
          </>
        }
        description="Whether you are a small business or an enterprise. We got you covered."
        cards={[
          {
            title: "Get started easily",
            description: (
              <p>You have a Shopify store? Great! Connect with FleetOnRoute using our
                &nbsp;<a href="/marketplace/shopify"><HighlightedText>Shopify App</HighlightedText></a>
              </p>
            )
          },
          {
            title: "No online shop?",
            description: "No problem. You can still use FleetOnRoute as a Delivery and Tracking System"
          },
          {
            title: "Smooth last-mile experience",
            description: "When your delivery person starts a delivery, your customers will be updated in real-time. Enrich your customers's last-mile experience and build your brand value."
          },
          {
            title: "Reliable",
            description: "You can monitor the location of your drivers while they're on route to their delivery or pickup location."
          },
          {
            title: "Scalable",
            description: "A few daily deliveries or hundreds, let our platform handle it."
          },
        ]}
      /> */}
      {/* <ForDrivers
        subheading={<Subheading></Subheading>}
      /> */}
      <DownloadApp />
      <GetStarted
        text="Delight your customers by using FleetOnRoute"
        primaryLinkUrl="https://portal.fleetonroute.com"
        secondaryLinkText="Install on Shopify"
        secondaryLinkUrl="https://apps.shopify.com/fleet-on-route"
      />
      <MainFooter />
    </AnimationRevealPage>
  );
}
