import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header, { NavLink, PrimaryLink } from "components/headers/light.js";

const trackingUrl = process.env.REACT_APP_TRACKING_URL;


export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    const [tripId, setTripId] = useState();
    function getTrackingId() {
        const val = document.querySelector("#tracking_id").value
        if (val) {
            console.log("Setting ", val)
            window.location.href = "/trip?id=" + val;
        }
    }
    useEffect(() => {
        const u = new URLSearchParams(window.location.search);
        setTripId(u.get("id"));
    });
    function TripSearchBox() {
        return (
            <div className="w-64 m-auto mt-16">
                <div className="">
                    <div className="text-center">
                        <p className="text-xl">Tracking Id</p>
                    </div>
                    <input id="tracking_id" className="border-black border-2 rounded w-full h-8" type="text" />
                </div>
                <div className="mt-2 text-center">
                    <button onClick={getTrackingId} className="bg-indigo-800 text-white px-4 py-2 w-full rounded">Go</button>
                </div>
            </div>
        );
    }
    return (
        <AnimationRevealPage disabled>
            <Header roundedHeaderButton={true} />
            {tripId && <iframe style={{ width: "100%", margin: "0%", minHeight: "calc(100vh - 150px)" }} src={`${trackingUrl}/${tripId}`} />}
            {!tripId && (
                <TripSearchBox />
            )}
        </AnimationRevealPage>
    );
}
